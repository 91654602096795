.root {
  :global {
    .ant-modal-content {
      background-color: transparent;
      box-shadow: none;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      width: 100%;
    }

    .ant-spin {
      color: white;
    }

    .ant-spin-dot-item {
      background-color: white;
    }
  }
}
