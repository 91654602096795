/* Custom Input */
.CustomInput .ant-input-suffix {
  width: 20px;
  justify-content: center;
  margin-left: 16px;
}

.CustomInput .anticon.ant-input-clear-icon {
  font-size: 16px;
  color: black;
}

.CustomInput .ant-input:focus,
.CustomInput .ant-input-focused {
  border-color: transparent;
  box-shadow: none !important;
}

.CustomInput .ant-input {
  font-size: 14px !important;
  line-height: 1.375rem;
}

.CustomInput .ant-input-prefix {
  margin-right: 8px;
}
/* End Custom Input */

/* Custom Input Redirect Url */
.CustomInputRedirectUrl .ant-input-suffix {
  width: 24px;
  justify-content: center;
  margin-left: 16px;
}

.CustomInputRedirectUrl .anticon.ant-input-clear-icon {
  font-size: 19px;
  color: black;
}
/* End Custom Input Redirect Url */

/* Custom Checkbox */
.CustomCheckbox .ant-checkbox-inner {
  border-radius: 4px;
}

.CustomCheckbox .ant-checkbox-checked .ant-checkbox-inner {
  background: linear-gradient(135deg, #FE3375 0%, #FF655B 100%);
  border: 1px solid;
  border-color: transparent;
}

.CustomCheckbox .ant-checkbox-checked::after {
  border: none;
}

.CustomCheckbox .ant-checkbox:hover::after {
  border-radius: 4px;
}
/* End Custom Checkbox */

/* Custom Input Password */
.CustomInputPassword .anticon.ant-input-clear-icon {
  font-size: 16px;
  color: black;
  margin-right: 16px;
}

.CustomInputPassword .ant-input-password-icon {
  font-size: 16px;
  color: black;
  transform: scaleX(-1);
}

.CustomInputPassword .ant-input-prefix {
  margin-right: 8px;
}
/* End Custom Input Password */

/* Custom Breadcrumb */
.CustomBreadcrumb .ant-breadcrumb-separator {
  color: #FF5064;
  margin: 0 4px;
}
/* End Custom Breadcrumb */

/* Custom Spin */
.CustomSpin .ant-spin-dot-item {
  background-color: #003760;
}

.CustomSpin .ant-spin-spinning {
  color: #003760;
}
/* End Custom Spin */

.select-filter-report .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #FF496A;
}

.optionCustom .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  border-color: #FE3375 !important;
  color: #FF5064 !important;
}

.input-search input {
  font-size: 12px !important;
}

.report-order-detail .ant-card-bordered {
  border-color: #BFBFBF;
}

.report-order-detail .ant-card-head {
  border-color: #BFBFBF;
  padding: 0 12px;
  min-height: 60px;
  font-size: 20px;
  font-weight: 500;
}

.report-order-detail .ant-card-body {
  padding: 0;
}

.report-border-dotted {
  border-bottom: dashed 1px #BFBFBF;
}

.report-filter-radio span {
  word-wrap: break-word;
  width: 100%;
}

/* Custom Radio */
.CustomRadio .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #FF5064 !important;
  border-width: 1px !important;
}
/* End Custom Radio */

.bot-drawer {
  width: 100%;
  left: 50% !important;
  transform: translate(-50%, 0%);
}

.auth-bot-drawer .ant-drawer-title {
  color: #FF5064
}

.sign-in .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  font-size: 14px;
  height: 46px;
  padding: 3px 11px;
  border-radius: 4px;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(255, 73, 106, 0.85) !important;
  background-color: rgba(255, 241, 240, 1) !important;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  /*color: rgba(255, 73, 106, 0.85) !important;*/
  /*background-color: rgba(255, 241, 240, 1) !important;*/
}

.sign-in-select .ant-select-selector {
  padding-left: 50px !important;
}

.ant-input-error {
  border-color: #FF4D4F !important;
  box-shadow: 0 0 0 2px rgb(255 80 100 / 20%) !important;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):not(.ant-input-error):hover {
  border-color: #1890ff !important;
}

/*.ant-input-affix-wrapper:not(.ant-input-error) .ant-input:focus, .ant-input-affix-wrapper:not(.ant-input-error) .ant-input-focused{*/
/*  border-color: #40a9ff!important;*/
/*  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%)!important;*/
/*}*/
.ant-select-error .ant-select-selector {
  border-color: #FF4D4F !important;
}

.ant-input-affix-wrapper:focus:not(.ant-input-error),
.ant-input-affix-wrapper-focused:not(.ant-input-error) {
  border-color: #40a9ff !important;
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%) !important;
}

.ant-input-affix-wrapper:focus:not(.ant-input-error) .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #1890ff !important;
}

.ant-input-affix-wrapper:focus:not(.ant-input-error) .ant-select:not(.ant-select-error):not(.ant-select-disabled):focus .ant-select-selector {
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%) !important;
}

.ant-select-focused.ant-select:not(.ant-select-error):not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #1890ff !important;
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%) !important;
}

.ant-input-number:focus:not(.ant-input-error),
.ant-input-number-focused {
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%) !important;
  border-color: #40a9ff !important;

}

.input-lowercase input {
  text-transform: lowercase;
}

.input-lowercase input::placeholder {
  text-transform: none;
}

.ant-input-lg::placeholder {
  font-size: 14px;
}

.ant-input{
  font-size: 12px!important;
}

.ant-input:focus, .ant-input-focused{
  border-color: #1890ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, .2);
}

/* Custom Modal */
.CustomModal .ant-modal-content {
  height: 100%;
  border-radius: 0;
}
/* End Custom Modal */

/* Custom Modal */
.CustomSelect .ant-select-selector {
  height: 46px !important;
  display: flex;
  align-items: center;
  padding-left: 40px !important;
}

.CustomErrorSelect .ant-select-selector {
  border-color: #FF4D4F !important;
}
/* End Custom Modal */

/* Custom DatePicker */
.CustomDatePicker .ant-picker-input > input {
  line-height: 1.25rem;
}

.CustomDatePickerPopup .ant-picker-content,
.CustomDatePickerPopup .ant-picker-date-panel {
  width: 100% !important;
}

.CustomDatePickerPopup .ant-picker-panel-container {
  margin: 4px 16px 0;
}
/* End Custom DatePicker */

/* Custom Progress */
.CustomProgress .ant-progress-inner {
  background: rgba(255, 255, 255, 0.4);
}
/* End Custom Progress */

/* Custom TextArea */
.CustomTextArea .ant-input:focus, .ant-input-focused {
  border-color: unset;
  box-shadow: unset !important;
}

.CustomTextArea .ant-input {
  font-size: 18px !important;
}

.CustomPriceShoutout .ant-input-affix-wrapper:focus:not(.ant-input-error), .ant-input-affix-wrapper-focused:not(.ant-input-error) {
  border-color: unset;
  box-shadow: unset !important;
}
/* End Custom TextArea */

/* Custom CustomProgressBar */
.CustomProgressBar .ant-progress-circle .ant-progress-text {
  color: white;
  font-size: 13px;
}
/* End CustomProgressBar */

.ant-checkbox-inner {
  width: 22px;
  height: 22px;
}
.ant-checkbox-inner::after {
  width: 10px;
  height: 10px;
}
.ant-checkbox-checked .ant-checkbox-inner::after {
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
  -ms-transform: rotate(45deg) scale(1) translate(-50%, -50%);
}
a.adm-popover-menu-item.adm-plain-anchor:hover {
  color: white!important;
}
.adm-selector .adm-space.adm-space {
  --gap: 0px!important;
}